<template>
    <div>
        <el-form
            v-if="refund"
            v-model="refund"
            ref="form"
            label-position="left"
            label-width="150px"
            @submit.native.stop.prevent="refundFormSubmit('refund')"
        >
            <h3>退款设置</h3>
            <el-form-item
                prop="coupon_refund"
                label="优惠券是否可退"
            >
                <el-radio-group v-model="refund.coupon_refund">
                    <el-radio :label="0">不可退</el-radio>
                    <el-radio :label="1">仅整单退款时可退</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop='box_refund'
                label="打包费是否可退"
            >
                <el-radio-group v-model="refund.box_refund">
                    <el-radio :label="0">不可退</el-radio>
                    <el-radio :label="1">仅整单退款时可退</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                prop='peis_refund'
                label="配送费是否可退"
            >
                <el-radio-group v-model="refund.peis_refund">
                    <el-radio :label="0">不可退</el-radio>
                    <el-radio :label="1">仅整单退款时可退</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                prop='refund_time_d'
                label="订单完成后可退时段"
            >
                <el-input
                    style="width:80px;margin-right:10px;color:#606266;"
                    type="number"
                    v-model.number="refund.refund_time_d"
                    :min="1"
                ></el-input>小时内可退，可退时段后将向用户发放消费积分。
            </el-form-item>

            <el-form-item
                prop='refund_time_d'
                label="驳回原因快捷输入"
            >
                <div class="b-card">
                    <div
                        class="kjsr"
                        style="margin-bottom:20px;"
                        v-for="(item) in refund.m_body"
                        :key="item.key"
                    >
                        <el-input
                            class="kjsr-i"
                            type="text"
                            maxlength="50"
                            v-model.trim="item.msg"
                            show-word-limit
                        >
                        </el-input>
                        <el-checkbox
                            class="kjsr-qy"
                            v-model="item.is_off"
                            @change="qyAllP(item)"
                        >启用</el-checkbox>
                        <el-button
                            class="kjsr-del"
                            type="text"
                            icon="el-icon-delete"
                            @click.prevent="removeBody(item)"
                        >删除</el-button>
                    </div>
                    <el-button
                        type="primary"
                        icon="el-icon-plus"
                        plain
                        @click="addBody"
                    >新增快捷输入</el-button>
                </div>
            </el-form-item>

            <el-form-item label="">
                <el-button
                    type="primary"
                    size="medium"
                    native-type="submit"
                    :loading="Loading"
                >保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'MiniProgram',
  data () {
    return {
      Loading: false,
      refund: null
    }
  },
  methods: {
    async refundFormSubmit () {
      const { data } = await flatry(SettingService.edit(this.refund))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/refund' })
      }
    },
    removeBody (item) {
      let index = this.refund.m_body.indexOf(item)
      if (index !== -1) {
        this.refund.m_body.splice(index, 1)
      }
    },
    addBody () {
      let id = this.refund.m_body.length
      if (id > 9) {
        this.$message.warning('不能超过10个，最大数量只支持10个:(')
        return
      }
      this.refund.m_body.push({
        is_off: false,
        msg: '',
        id: id + 1
      })
    },
    // 点击启用时，其他的都变为false，就我为真
    qyAllP (item) {
      this.refund.m_body.forEach(element => {
        if (element.id === item.id && item.is_off === true) {
          element.is_off = true
        } else {
          element.is_off = false
        }
      })
    }
  },
  async mounted () {
    let param = {
      type: 5,
      coupon_refund: 0,
      box_refund: 0,
      peis_refund: 0,
      refund_time_d: '24',
      m_body: [{ is_off: false, msg: '' }]
    }
    const { data } = await flatry(SettingService.edit())
    if (data) {
      if (data.data.refund) {
        this.refund = data.data.refund.body
        this.refund.type = 5
      } else {
        this.refund = param
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.kjsr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .kjsr-i {
        margin-right: 30px;
        padding-right: 50px;
    }
    .kjsr-qy {
    }
    .kjsr-del {
        width: 80px;
        color: #f56c6c;
    }
}
</style>
